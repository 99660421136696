/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { cache } from '@/utils/serverQueryCache';
import { AxiosInstance } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { VehicleYearsResponse, YmmeDropdownData } from '../interface';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

type YearsQueryOptions = {
  locale: Locale;
  preview: boolean;
  category: 'years';
};

const URL = `${requestBaseURL}/external/product-discovery/vehicle-identification/v1/vehicle-years`;

const {
  query: vehicleYearsQuery,
  useData: useVehicleYears,
  prefetch: prefetchVehicleYears,
} = createQuery<YmmeDropdownData, YearsQueryOptions>('ymme', async (options, axiosInstance) =>
  getYmmeYears(options, axiosInstance)
);

const getYmmeYears = async (options: YearsQueryOptions, axiosInstance?: AxiosInstance) => {
  const { locale, preview } = options;

  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';
  const vehicleMakeName = undefined;
  const vehicleModelName = undefined;

  const response = await getAxios(axiosInstance).get<VehicleYearsResponse>(URL, {
    params: {
      country,
      customerType,
      salesChannel,
      vehicleMakeName,
      vehicleModelName,
      preview,
    },
    withCredentials: false,
  });
  return getVehicleYearsSelector(response.data);
};

const getVehicleYearsSelector = (data: VehicleYearsResponse): YmmeDropdownData => {
  return data.map((item) => ({ value: item.vehicleYear, label: item.vehicleYear }));
};

export const useYmmeYears = () => {
  const locale = useLocale();

  return useVehicleYears({
    locale,
    category: 'years',
    staleTime: Infinity,
    preview: showXMPreviewDate(),
  });
};

export const prefetchYmmeYears = async (
  queryClient: QueryClient,
  locale: Locale,
  axiosInstance: AxiosInstance,
  preview: boolean
) => {
  const options = {
    locale,
    preview,
    category: 'years',
  } as const;

  const queryKey = vehicleYearsQuery.getFullKey(options);

  const data = cache.getItem(queryKey);
  if (data) {
    queryClient.setQueryData(queryKey, data);
    return;
  }

  await prefetchVehicleYears(queryClient, options, axiosInstance);

  const prefetchedData = queryClient.getQueryData<YmmeDropdownData>(queryKey);

  prefetchedData && cache.setItem(queryKey, prefetchedData);
};
