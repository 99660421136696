/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { VehicleEnginesResponse, YmmeDropdownData } from '../interface';
import type { Country, CustomerType, SalesChannel } from '@/types';
import { AxiosInstance } from 'axios';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

type EnginesQueryOptions = {
  locale: Locale;
  vehicleMakeName: string | null | undefined;
  vehicleModelName: string | null | undefined;
  vehicleYear: string | null | undefined;
  modelId: string | null | undefined;
  category: 'engines';
  preview: boolean;
};

const URL = `${requestBaseURL}/external/product-discovery/vehicle-identification/v1/vehicle-engines`;

const { useData: useVehicleEngines } = createQuery<YmmeDropdownData, EnginesQueryOptions>(
  'ymme',
  async (options, axiosInstance) => getYmmeEngines({ ...options }, axiosInstance)
);

const getYmmeEngines = async (options: EnginesQueryOptions, axiosInstance?: AxiosInstance) => {
  const { vehicleYear, vehicleMakeName, vehicleModelName, modelId, locale, preview } = options;

  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!modelId) {
    throw new Error('modelId is required');
  }

  if (!vehicleMakeName) {
    throw new Error('vehicleMakeName is required');
  }

  if (!vehicleModelName) {
    throw new Error('vehicleModelName is required');
  }

  if (!vehicleYear) {
    throw new Error('vehicleYear is required');
  }

  const params: {
    country: Country;
    customerType: CustomerType;
    salesChannel: SalesChannel;
    vehicleMakeName: string;
    vehicleModelName: string;
    vehicleYear: string;
    preview: boolean;
  } = {
    country,
    customerType,
    salesChannel,
    vehicleMakeName,
    vehicleModelName,
    vehicleYear,
    preview,
  };

  const response = await getAxios(axiosInstance).get<VehicleEnginesResponse>(URL, {
    params,
  });
  return getVehicleEnginesSelector(response.data);
};

const getVehicleEnginesSelector = (data: VehicleEnginesResponse): YmmeDropdownData => {
  return data.map((item) => ({
    // v2 useVehiclesMutation only accepts vehicleId
    // additionally, v1 engines api would return vehicleIds as engineIds
    // e.g. /ecomm/b2c/v1/ymme/engines/9849980
    // We do not actually use 4-digit engine IDs anywhere
    value: item.azVehicleId,
    label: item.azVehicleEngineName,
  }));
};

type UseYmmeEnginesOptions = {
  enabled?: boolean;
  vehicleMakeName: string | null | undefined;
  vehicleModelName: string | null | undefined;
  vehicleYear: string | undefined;
  modelId: string | null | undefined;
};

export const useYmmeEngines = ({ enabled, ...options }: UseYmmeEnginesOptions) => {
  const locale = useLocale();

  return useVehicleEngines({
    ...options,
    enabled,
    locale,

    category: 'engines',
    cacheTime: 0,
    preview: showXMPreviewDate(),
  });
};
