/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { VehicleMakesResponse, YmmeDropdownData } from '../interface';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { AxiosInstance } from 'axios';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

const URL = `${requestBaseURL}/external/product-discovery/vehicle-identification/v1/vehicle-makes`;

type Options = {
  category: 'makes';
  locale: Locale;
  vehicleYear: string;
  preview: boolean;
};

const { useData: useVehicleMakes } = createQuery<YmmeDropdownData, Options>(
  'ymme',
  async (options, axiosInstance) => getYmmeMakes({ ...options }, axiosInstance)
);

const getYmmeMakes = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { locale, vehicleYear, preview } = options;
  const country = getCountryFromLocale(locale);

  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const response = await getAxios(axiosInstance).get<VehicleMakesResponse>(`${URL}`, {
    params: {
      country,
      customerType,
      salesChannel,
      vehicleYear,
      preview,
    },
  });
  return newSelector(response.data);
};

const newSelector = (data: VehicleMakesResponse): YmmeDropdownData => {
  return data.map((item) => ({
    value: item.vehicleMakeName,
    label: item.vehicleMakeName,
  }));
};

type UseYmmeMakesOptions = {
  vehicleYear?: string;
  enabled?: boolean;
};

export const useYmmeMakes = ({ vehicleYear, enabled }: UseYmmeMakesOptions) => {
  const locale = useLocale();

  return useVehicleMakes({
    enabled,
    category: 'makes',
    locale,
    vehicleYear: vehicleYear ?? '',

    preview: showXMPreviewDate(),
  });
};
