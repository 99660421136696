/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useYmmeEngines } from '../api/getYmmeEngines';
import { usePageType } from '@/features/category/api/getPageType';

export function useYmmeDefaultData() {
  const { data: pageTypeData } = usePageType();
  const { data: engines } = useYmmeEngines({
    vehicleMakeName: pageTypeData?.make,
    vehicleModelName: pageTypeData?.model,
    vehicleYear: pageTypeData?.year || undefined,
    modelId: pageTypeData?.model,
    enabled: Boolean(pageTypeData?.year && pageTypeData.model && pageTypeData.make),
  });
  const defaultEngine = engines && engines.length === 1 ? engines[0] : null;

  return {
    data: {
      defaultEngine,
      defaultModel: !!pageTypeData?.model
        ? {
            value: pageTypeData.model,
            label: pageTypeData.model,
          }
        : null,
      defaultSubModel: null,
      defaultMake: !!pageTypeData?.make
        ? { value: pageTypeData.make, label: pageTypeData.make }
        : null,
      defaultYear: pageTypeData?.year,
    },
  };
}
