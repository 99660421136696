/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { myVehicleConstant } from '@/constants/myVehicle';
import { getHomePageKey } from '@/features/home/api/getHomePageContent';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { useEffect, useReducer } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useYmmeEngines } from '../../api/getYmmeEngines';
import { useYmmeMakes } from '../../api/getYmmeMakes';
import { useYmmeModels } from '../../api/getYmmeModels';
import { useYmmeYears } from '../../api/getYmmeYears';
import { useVehiclesMutation } from '../../api/postVehicles';
import { ymmeActionSources } from '../../utils/ymmeHelpers';
import { setYmmeActionSource } from '../../utils/ymmeActionSourceHelper';
import { setInteractionLocation } from '../../utils/ymmeLocationHelper';
import styles from '../YMMEContentBlock.module.scss';
import YmmeSelectRender from './YmmeSelectRender';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { findListItemByLabel } from '../../utils/findListItemByLabel';
import usePrevious from '@/utils/usePrevious';
import { useYmmeDefaultData } from '../../utils/useYmmeDefaultData';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { usePageType } from '@/features/category/api/getPageType';
import type { InteractionLocation } from '@/types/analytics';

export type YMMESelectCompProps = {
  onSelectVehicle?: (a: string) => void;
  pageType: string;
  showYmmeMobile?: () => void;
  forceMobile?: boolean;
  onUpdateVehicleSuccess?: () => void;
};

const ymmeConst = {
  years: 'YEARS',
  make: 'MAKE',
  model: 'MODEL',
  engine: 'ENGINE',
};

type YmmeConst = (typeof ymmeConst)[keyof typeof ymmeConst];

type YMMEObject = {
  value: string;
  label: string;
};

type State = {
  currentYear: string | undefined;
  currentMake: YMMEObject | undefined;
  currentModel: YMMEObject | undefined;
  currentEngine: string | undefined;
  focus: string | undefined;
};

const initialState = (
  defaultMake: YMMEObject | null | undefined,
  defaultModel: YMMEObject | null | undefined,
  defaultYear: string | null | undefined
): State => ({
  focus: undefined,
  currentEngine: undefined,
  currentMake: defaultMake?.value
    ? { label: defaultMake.label, value: defaultMake.value }
    : undefined,
  currentModel: defaultModel?.value
    ? { label: defaultModel.label, value: defaultModel.value }
    : undefined,
  currentYear: defaultYear ?? '',
});

const stateReducer = (
  state: State,
  action: {
    type: string;
    data?: Partial<State>;
  }
): State => {
  switch (action.type) {
    case myVehicleConstant.updateField:
      return {
        ...state,
        ...action.data,
      };
    case 'reset':
      return {
        ...state,
        currentEngine: undefined,
        currentModel: undefined,
        currentMake: undefined,
        currentYear: undefined,
      };
  }

  return state;
};

/*
  React component to control the state of the YmmeSelectRender component.
  It will control the current values each input has and when
  to focus on a specific input
*/
export const YmmeSelectComponent = ({
  pageType,
  onSelectVehicle,
  showYmmeMobile,
  forceMobile,
  onUpdateVehicleSuccess,
}: YMMESelectCompProps) => {
  const locale = useLocale();
  const { data: ymmeData } = useYmmeDefaultData();
  const { data: pageTypeData } = usePageType();
  const { defaultMake, defaultModel, defaultYear } = ymmeData;
  const enablePrefilledData = !!defaultYear || !!defaultMake || !!defaultModel;
  const [state, stateDispatch] = useReducer(
    stateReducer,
    initialState(defaultMake, defaultModel, defaultYear)
  );
  const { currentYear, currentMake, currentModel, currentEngine } = state;
  const { workspaceId, previewDate } = useXMPreviewState();
  const ymmeYearQuery = useYmmeYears();
  const ymmeMakeQuery = useYmmeMakes({
    vehicleYear: currentYear,
    enabled: !!currentYear,
  });
  const previousMakeList = usePrevious(ymmeMakeQuery.data);
  const syncedMake = syncCurrentMakeWithNewMakeList();
  const ymmeModelQuery = useYmmeModels({
    vehicleYear: currentYear,
    vehicleMakeName: syncedMake?.value,
    enabled: !!syncedMake && !ymmeMakeQuery.isLoading && !!currentYear,
  });
  const previousModelList = usePrevious(ymmeModelQuery.data);
  const syncedModel = syncCurrentModelWithNewModelList();
  const ymmeEngineQuery = useYmmeEngines({
    modelId: syncedModel?.value,
    vehicleModelName: syncedModel?.label,
    vehicleMakeName: syncedMake?.label,
    vehicleYear: currentYear,
    enabled:
      !!currentYear &&
      !!syncedModel?.value &&
      !ymmeMakeQuery.isLoading &&
      !ymmeModelQuery.isLoading,
  });
  const isError =
    ymmeYearQuery.error || ymmeMakeQuery.error || ymmeModelQuery.error || ymmeEngineQuery.error;
  const vehiclesMutation = useVehiclesMutation({
    onSuccess: () => {
      onUpdateVehicleSuccess?.();
      void queryClient.refetchQueries(getHomePageKey(locale, workspaceId, previewDate));
    },
  });
  const queryClient = useQueryClient();

  function syncCurrentMakeWithNewMakeList() {
    //when the make list changes and we have a prefilled make, we need to preserve that value by finding it in the new list and selecting it
    const makesListChanged = previousMakeList !== ymmeMakeQuery.data;
    if (enablePrefilledData && currentMake && ymmeMakeQuery.data && makesListChanged) {
      const newMake = findListItemByLabel(ymmeMakeQuery.data, currentMake.label);
      return newMake;
    }
    return currentMake;
  }

  function syncCurrentModelWithNewModelList() {
    //when the model list changes and we have a prefilled model, we need to preserve that value by finding it in the new list and selecting it
    const modelListChanged = previousModelList !== ymmeModelQuery.data;
    if (enablePrefilledData && currentModel && ymmeModelQuery.data && modelListChanged) {
      const newModel = findListItemByLabel(ymmeModelQuery.data, currentModel.label);
      return newModel;
    }
    return currentModel;
  }

  useEffect(() => {
    if (enablePrefilledData && syncedMake?.value !== currentMake?.value) {
      stateDispatch({
        type: myVehicleConstant.updateField,
        data: {
          currentMake: syncedMake ? syncedMake : undefined,
          currentModel: syncedMake ? currentModel : undefined,
          focus:
            !!syncedMake && !currentModel ? ymmeConst.model : !syncedMake ? ymmeConst.make : '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncedMake]);

  useEffect(() => {
    if (enablePrefilledData) {
      stateDispatch({
        type: myVehicleConstant.updateField,
        data: {
          currentModel: syncedModel ? syncedModel : undefined,
          focus: !!syncedModel ? ymmeConst.engine : ymmeConst.model,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncedModel]);

  useEffect(() => {
    //vehicle is added in ymme content block for this scenario hence preventing duplicate calls here !shopByUrlSuffixHasYear
    if (
      ymmeEngineQuery.data?.length === 1 &&
      currentYear &&
      currentMake &&
      currentModel &&
      ((pageTypeData &&
        (pageTypeData.year !== currentYear ||
          pageTypeData.make !== currentMake.label ||
          pageTypeData.model !== currentModel.label ||
          !pageTypeData.year)) ??
        true)
    ) {
      const { value, label } = ymmeEngineQuery.data[0];
      setYmmeActionSource(ymmeActionSources.contentBlock);
      onSelectVehicle?.(value);
      vehiclesMutation.mutate({ vehicleId: value });
      stateDispatch({
        type: myVehicleConstant.updateField,
        data: { currentEngine: label },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- selectEngine would need to be memoized
  }, [ymmeEngineQuery.data, vehiclesMutation.mutate]);

  const resetAll = () => {
    stateDispatch({ type: 'reset' });
  };

  const handleSubmit = (inputType: YmmeConst, value?: string | null) => {
    let data: Partial<State> = {};
    const preserveSelectedValues = value && enablePrefilledData;
    setInteractionLocation(pageType as InteractionLocation);

    switch (inputType) {
      case ymmeConst.years: {
        if (!value) {
          resetAll();
        }
        data = {
          currentYear: findListItemByLabel(ymmeYearQuery.data, value)?.label,
          currentMake: preserveSelectedValues && currentMake ? currentMake : undefined,
          currentModel: preserveSelectedValues && currentModel ? currentModel : undefined,
          currentEngine: preserveSelectedValues && currentEngine ? currentEngine : undefined,
          focus:
            preserveSelectedValues && currentMake && !currentModel
              ? ymmeConst.model
              : preserveSelectedValues && currentMake
              ? undefined
              : ymmeConst.make,
        };
        break;
      }

      case ymmeConst.make: {
        data = {
          currentMake: findListItemByLabel(ymmeMakeQuery.data, value),
          currentModel: preserveSelectedValues && currentModel ? currentModel : undefined,
          currentEngine: preserveSelectedValues && currentEngine ? currentEngine : undefined,
          focus: ymmeConst.model,
        };
        break;
      }

      case ymmeConst.model: {
        data = {
          currentModel: findListItemByLabel(ymmeModelQuery.data, value),
          currentEngine: preserveSelectedValues && currentEngine ? currentEngine : undefined,
          focus: currentYear ? ymmeConst.engine : ymmeConst.years,
        };
        break;
      }

      case ymmeConst.engine: {
        const selectedEngine = findListItemByLabel(ymmeEngineQuery.data, value);
        if (selectedEngine && currentYear) {
          setYmmeActionSource(ymmeActionSources.contentBlock);
          onSelectVehicle?.(selectedEngine.value);
          vehiclesMutation.mutate({ vehicleId: selectedEngine.value });
        }
        data = {
          currentEngine: selectedEngine?.label,
        };
        break;
      }

      default:
        break;
    }

    stateDispatch({
      type: myVehicleConstant.updateField,
      data,
    });
  };

  return (
    <>
      {vehiclesMutation.isLoading && <ProgressModal noScroll />}
      <YmmeSelectRender
        dataSource={[ymmeYearQuery, ymmeMakeQuery, ymmeModelQuery, ymmeEngineQuery]}
        resetFocus={() => {
          stateDispatch({
            type: myVehicleConstant.updateField,
            data: { focus: undefined },
          });
        }}
        pageType={pageType}
        showYmmeMobile={showYmmeMobile}
        forceMobile={forceMobile}
        selectedEngine={currentEngine}
        selectedMake={currentMake?.label}
        selectedModel={currentModel?.label}
        selectedYear={currentYear}
        selectEngine={(value) => handleSubmit(ymmeConst.engine, value)}
        selectMake={(value) => handleSubmit(ymmeConst.make, value)}
        selectModel={(value) => handleSubmit(ymmeConst.model, value)}
        selectYear={(value) => handleSubmit(ymmeConst.years, value)}
        resetAll={resetAll}
        focusYear={state.focus === ymmeConst.years}
        focusMake={state.focus === ymmeConst.make}
        focusModel={state.focus === ymmeConst.model}
        focusEngine={state.focus === ymmeConst.engine}
        enablePrefilledData={enablePrefilledData}
      />

      {isError ? (
        <div className={styles.ymmeErrorMsg}>
          {!!ymmeModelQuery.error && (
            <Label label="hyperlink_YMME_Body_NoDataFoundForTheSelectedMake" />
          )}
          {!!ymmeEngineQuery.error && (
            <Label label="hyperlink_YMME_Body_NoDataFoundForTheSelectedModel" />
          )}
          {!!ymmeMakeQuery.error && (
            <Label label="hyperlink_YMME_Body_NoDataFoundForTheSelectedYear" />
          )}
          {!!ymmeYearQuery.error && <Label label="hyperlink_YMME_Body_OopsSomethingWentWrong" />}
        </div>
      ) : null}
    </>
  );
};
